export default [
   {
      key: 'new',
      label: {
         fr: 'Nouveau',
         en: 'New',
      },
   },
   {
      key: 'pending',
      label: {
         fr: 'Attente Téléchargement',
         en: 'Pending Download',
      },
   },
   {
      key: 'converting',
      label: {
         fr: 'Conversion en cours',
         en: 'Conversion in progress',
      },
   },
   {
      key: 'downloading',
      label: {
         fr: 'Téléchargement en cours',
         en: 'Download in progress',
      },
   },
   {
      key: 'downloaded',
      label: {
         fr: 'Téléchargement terminé',
         en: 'Download completed',
      },
   },
   {
      key: 'trimming',
      label: {
         fr: 'Recoupage de la vidéo',
         en: 'Video Trimming',
      },
   },
   {
      key: 'queued',
      label: {
         fr: "En file d'attente",
         en: 'Queued',
      },
   },
   {
      key: 'processing',
      label: {
         fr: 'Traitement en cours',
         en: 'Processing',
      },
   },
   {
      key: 'vectorization',
      label: {
         fr: 'Vectorisation en cours',
         en: 'Vectorization in progress',
      },
   },
   {
      key: 'convert_x264',
      label: {
         fr: 'Conversion x264',
         en: 'x264 Conversion',
      },
   },
   {
      key: 'video360',
      label: {
         fr: 'Génération vidéo en cours',
         en: 'Video Generation in progress',
      },
   },
   {
      key: 'compressing',
      label: {
         fr: 'Compression',
         en: 'Compression',
      },
   },
   {
      key: 'uploading',
      label: {
         fr: 'Envoi en cours',
         en: 'Upload in progress',
      },
   },
   {
      key: 'completed',
      label: {
         fr: 'Terminé',
         en: 'Completed',
      },
   },
   {
      key: 'timeout',
      label: {
         fr: 'Temps dépassé',
         en: 'Timeout',
      },
   },
   {
      key: 'ko',
      label: {
         fr: 'Erreur',
         en: 'Error',
      },
   },
   {
      key: 'renaming',
      label: {
         fr: 'Renommage en cours',
         en: 'Renaming in progress',
      },
   },
]
