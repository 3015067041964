import Api from "../../Api";
import FormComponent from "../../Form";
import labels from "../../Common/translations";
import specialUsageSelector from "./specialUsageSelector";
import * as ROLES from "../../../constants/roles";

export default {
  fields: {
    file: {
      type: "file",
      label: labels.fields.file,
      mandatory: true,
      options: {
        folder: "",
        //storage: 'aws',
      },
      protected: true,
      observers: [
        {
          observable: "file",
          action: (data) => {
            if (data.file && data.file.isTempFile) {
              return {
                formData: {
                  ...(data.name === "" && { name: data.file.label }),
                  contentType: data.file.contentType,
                  checksum: `b64-${data.file.md5Hash}`,
                },
              };
            }
          },
        },
      ],
    },
    core: {
      type: FormComponent.GROUP,
      fields: {
        name: {
          label: labels.fields.name,
          mandatory: true,
        },
        specialUsage: {
          label: labels.fields.specialUsage,
          type: FormComponent.SELECT,
          values: {
            from: "state",
            prop: "specialUsageValues",
            source: () =>
              new Promise((resolve) =>
                resolve(specialUsageSelector.slice(0, 2))
              ),
            processor: (val) => val && val.ref.split("/").pop(),
          },
        },
        tags: {
          label: labels.fields.tags,
          formatter: (e) => {
            return Array.isArray(e) ? e.join(",") : e;
          },
        },
      },
    },
    company: {
      label: labels.fields.company,
      type: FormComponent.SELECT,
      values: {
        from: "state",
        prop: "companyValues",
        collection: "companies",
        source: () => Api.selectValues("companies", { fsort: "name" }),
      },

      return: FormComponent.FORMAT_OBJECT,
      mandatory: true,
      disabled: ({ authUser }, { data }) =>
        (data.uid !== undefined && data.company !== "") ||
        !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
      options: {
        defaultValue: ({ authUser }) => authUser.company,
      },
    },
    meta: {
      type: FormComponent.GROUP,
      fields: {
        contentType: {
          label: labels.fields.contentType,
          mandatory: true,
        },
        checksum: {
          label: labels.fields.checksum,
          mandatory: true,
        },
      },
    },
  },
  buttons: {
    cancel: true,
    submit: true,
    delete: true,
    extras: [],
  },
  actions: {
    create: (data) => Api.instance().post("medias/", data),
    read: (uid) => Api.instance().get(`${uid}`),
    update: ({ uid, ...data }) => Api.instance().patch(`medias/${uid}`, data),
    delete: ({ uid }) => Api.instance().delete(`medias/${uid}`),
  },
  extras: [],
};
